.tickets {
  font-family: 'Roboto';
  margin: 15px 70px;
  
.ticket {
    backface-visibility: hidden;
    margin-top: 15px;
    margin-left: 15px;
    display: inline-block;
    padding: 15px 25px;
    background-image:
      radial-gradient(ellipse closest-side at 50% 50%, hsla(0, 0%, 100%, 0.10), transparent 90%),
      radial-gradient(circle at 0 100%, transparent 14px, #fa616d 15px),
      radial-gradient(circle at 100% 100%, transparent 14px, #fa616d 15px),
      radial-gradient(circle at 100% 0, transparent 14px, #fa616d 15px),
      radial-gradient(circle at 0 0, transparent 14px, #fa616d 15px);
    background-position: center center, bottom left, bottom right, top right, top left;
    background-size: cover, 51% 51%, 51% 51%, 51% 51%, 51% 51%;
    background-repeat: no-repeat;
    border-width: 0 4px;
    border-color: transparent;
    border-style: solid;
    border-image: radial-gradient(cover circle, #fa616d 0%, #fa616d 50%, transparent 51%) 0 39% / 15px 4px repeat;
    -webkit-filter: drop-shadow(hsla(0, 0%, 0%, 0.55) 1px 1px 2px);
  }

  .ticket>div { 
    width: 320px;
    height: 100px;
    box-sizing: border-box;
    position: relative;
    border-color: #ea4a56;
    border-style: solid;
    border-width: 2px;
    border-radius: 5px;
    text-align: center;
    font: 2em/1 Impact;
    text-transform: uppercase;
    padding: 15px;
    font-stretch: extra-expanded;
    white-space: pre-wrap;
  }

  .ticket>div:before,
  .ticket>div:after {
    content: attr(data-number);
    font: .625em/1 'Courier New';
    position: absolute;
    width: 96px;
    line-height: 28px;
    transform: rotate(90deg) translateZ(0);
    transform-origin: 0% 0%;
    text-align: center;
    display: block;
    top: 0;
    border-color: #ea4a56;
    border-style: solid;
    border-width: 2px 0 0;
  }

  .ticket>div:before {
    left: 28px;
  }

  .ticket>div:after {
    transform-origin: 100% 0%;
    right: 28px;
    transform: rotate(-90deg) translateZ(0);
  }
}
@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}