.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
}

.modal h1 {
  color: rgb(74, 8, 117);
  font-size: 48pt;
}

.modal-main {
  position: fixed;
  background: #AFAFAF;
  width: 80%;
  height: auto;
  top: 50%;
  left: 50%;
  padding-bottom: 2%;
  transform: translate(-50%, -50%);
}

.display-block {
  display: block;
}

.display-none {
  display: none;
}